@import "../../../assets/stylesheets/core/dependencies";

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
  }

  .button-icon {
    margin-right: 8px;
  }

  &.no-label {
    .button-icon {
      margin-right: 0px;
    } 
  }

  &.minimal {
    min-width: 0;

    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 40px;
    font-weight: 500;

    .button-label {
      text-transform: none;
    }
  }

  &.small {
    padding: 4px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 40px;

    .button-label {
      text-transform: none;
    }
  }

  &.subtle {
    background: none;
    text-decoration: underline;
    font-weight: normal;
    text-transform: none;
    color: $theme-complementary-color;
    padding: 0;
    min-width: 0;

    &::after {
      content: ">";
      display: inline-block;
      margin-left: 4px;
    }
  }

  &.disabled {
    opacity: 0.3;
  }

  &.primary {
    background: $theme-primary-color-lighter;
  }

  &.secondary {
    background: orange;
  }

  &.no-background {
    background: none;
  }
}