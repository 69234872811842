.board-scene {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $primary-font-family;

  .player-avatar-display {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    color: white;
    z-index: 10;
    padding: 8px;
    background-color: $theme-primary-color-darker;
    border-radius: 8px;

    .player-avatar {
      margin-right: 8px;
    }

    @include breakpoint(small down) {
      display: none;
    }

    @media(orientation: portrait) {
      top: 86px;
      left: 12px;
    }
  }
}

.board-error-toast {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: red;
  font-size: 12px;
  color: white;
  font-weight: 600;
  text-align: center;

  @media(orientation: portrait) {
    bottom: 130px;
  }
}
