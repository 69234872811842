@import "../../assets/stylesheets/core/dependencies";

.perspective-card {
  position: absolute;
  left: -30px;
  top: -10px;
  right: -30px;
  bottom: 0px;
  border-radius: 8px;
  transform: translateZ(-5px);

  &.suit-clubs {
    background-color: $clubs-color;
  }
  &.suit-diamonds {
    background-color: $diamonds-color;
  }
  &.suit-hearts {
    background-color: $hearts-color;
  }
  &.suit-spades {
    background-color: $spades-color;
  }

  .perspective-card-description {
    position: absolute;
    top: 12px;
    left: 5px;
    right: 5px;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    margin: 0;
    white-space: nowrap;
    filter: drop-shadow(0px 0px 20px white);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}