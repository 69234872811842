@import "../../../../../../assets/stylesheets/core/dependencies";

.played-card-bar-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 4px 12px #ddd;
  position: relative;

  .played-card-bar-item-content {
    text-align: center;
    max-width: calc(100% - 8px);

    .card-title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;

      @media(max-width: 600px) {
        font-size: 12px;
      }
    }
  }

  .change-card-button {
    position: absolute;
    top: -8px;
    right: -8px;
    border: none;
    background: none;
    color: $theme-accent-color;
    cursor: pointer;
  }
}