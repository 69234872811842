@import "../../../../assets/stylesheets/core/dependencies";

.active-participation-view-background {
  content: "";
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  overflow-y: hidden;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1999;
}

.active-participation-view {
  position: fixed;
  top: env(keyboard-inset-bottom, 0);
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 2000;

  @media(orientation: landscape) {
    max-height: 1024px;
  }

  max-width: 800px;
  margin: auto;
  background: white;
  outline: 2px solid $theme-primary-color-darker;
  box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.25);

  &:focus {
    outline: none;
  }

  @mixin focus-visible {
    @media(min-width: 1024px) {
      outline: 2px solid $theme-accent-color;
    }
  }

  &:focus-visible {
    @include focus-visible;
  }

  &.focus-visible {
    @include focus-visible;
  }

  .indented-content {
    padding: 20px;

    @media(max-width: 500px) {
      padding: 10px;
      padding-bottom: 8px;
    }
  }

  .active-participation-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("../../../../assets/images/dark-background.png");
    padding: 20px;
    
    #active-participation-topic-title {
      color: white;
      flex: auto;
      text-align: left;
      padding-right: 12px;

      @media(max-width: 500px) {
        font-size: 18px;
        margin-bottom: 8px;
        margin-top: 0;
      }
    }

    .topaasia-label {
      color: white;
      font-weight: 300;
    }
  }

  .active-participation-view-scrollable-portion {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    background-color: $theme-primary-color-desaturated-white;
  }

  .help-content {
    margin: 8px;
  }

  .active-participation-footer-content {
    //flex: 0.25;
    box-shadow: 0px -3px 8px rgba($color: #000000, $alpha: 0.25);
    z-index: 100;
  }

  h2 {
    font-size: 22px;
    text-align: center;
    font-family: "Poppins", sans-serif;

    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 1px;
      background-color: $theme-primary-color-extralight;
      margin: auto;
      margin-top: 12px;
    }

    @media(max-width: 500px) {
      font-size: 18px;
    }
  }

  .timer-container {
    text-align: center;
    font-size: 28px;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-variant-numeric: tabular-nums;
    font-family: "Work Sans", sans-serif;

    @media(max-width: 500px) {
      font-size: 20px;
    }
  }

  .input-container {
    display: flex;
    align-items: flex-end;

    .textarea-container {
      flex: auto;
      padding-right: 12px;
    }
  }

  .actions-container {
    margin-bottom: 4px;

    @media(max-width: 768px) {
      width: 50px;
    }

    .send-button {
      margin: auto;
      height: 44px;

      @media(max-width: 768px) {
        padding-left: 4px;
        padding-right: 4px;
        min-width: 0;
        width: auto;
        min-width: 0;
        padding-left: 30px;
        padding-right: 20px;

        .button-label {
          display: none;
        }
      }
    }
  }

  .help-paragraph {
    // text-align: center;
    color: $theme-primary-color-lighter;
    padding-left: 12px;
    padding-right: 12px;
  }

  .finished-indicator-container {
    margin-top: 20px;
    text-align: right;

    input {
      display: inline-block;
      width: auto;
      margin-right: 8px;
    }

    label {
      color: white;
      font-weight: 600;
    }
  }

  .finished-help {
    padding: 12px;
    background: $theme-primary-color;
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin-top: 20px;
    border-radius: 8px;
  }

  textarea {
    width: 100%;
    padding: 12px;
    resize: none;
    box-sizing: border-box;
  }
}
