.card-game-note-list-item {
  display: flex;
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px;
  gap: 12px;
  color: white;

  border-bottom: 1px solid #555;

  .player-avatar {
    flex-shrink: 0;
  }

  .game-note-message {
    align-self: center;
  }
}
