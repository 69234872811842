.button {
  border: none;
  background: $theme-complementary-color;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  min-width: 200px;
  max-width: calc(100% - 24px);
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
      cursor: pointer;
  }

  &.button--disabled {
    opacity: 0.3;
  }

  &:disabled {
    background-color: #555;
    opacity: 0.3;
  }

  &.subtle {
    background: none;
    text-decoration: underline;
    font-weight: normal;
    text-transform: none;
    color: $theme-complementary-color;

    &::after {
      content: ">";
      display: inline-block;
      margin-left: 4px;
    }
  }

  &.small {
    border-radius: 20px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    padding-left: 12px;
    padding-right: 12px;
  }
}
