@import "../../../../assets/stylesheets/core/dependencies";

.crystallize-tab-view {
  .crystallize-tab-view-desktop-content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $theme-primary-color-desaturated-white;
    background: url("../../../../assets/images/dark-background-lighter.png");

    .column-left, .column-right {
      position: absolute;
      top: 20px;
      bottom: 20px;
      overflow-y: hidden;
    }

    .column-left {
      left: 20px;
      right: 40%;
    }

    .column-right {
      right: 20px;
      left: calc(60% + 20px);
    }

    .crystallize-view-column {
      height: calc(100% - 40px);
      overflow-y: auto;
      border-radius: 8px;

      &:focus {
        outline: none;
      }

      @mixin focus-visible {
        outline: none;
      }

      &:focus-visible {
        @include focus-visible;
      }

      &.focus-visible {
        @include focus-visible;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba($color: #CCC, $alpha: 0.2);
        border-radius: 8px;
        margin: 10px;
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba($color: $theme-accent-color, $alpha: 0.5);
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgba($color: $theme-accent-color, $alpha: 1.0);;
      }

      .conclusion-row {
        padding: 12px;
        border-radius: 8px;
        border: 1px dashed $theme-primary-color-lighter;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;

        .finding-report {
          padding: 0;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .no-action-items-label {
          padding: 0;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    
    .crystallize-plan-column {
      padding: 20px;
      overflow-y: auto;

      .crystallize-plan-column-content-wrapper {
        max-width: 800px;
        margin: auto;
      }
    }

    .crystallize-notes-column {
      padding: 20px;
      margin-left: 10px;
      overflow-y: auto;

      @mixin focus-visible {
        outline: none;
      }

      &:focus-visible {
        @include focus-visible;
      }

      &.focus-visible {
        @include focus-visible;
      }
    }
  }
}
