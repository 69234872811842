html {
  // overflow: hidden;
  // position: relative;
  // height: 100vh;
  // height: -webkit-fill-available;
  overscroll-behavior: none;

  &.card-background {
    background: url("../images/dark-background.png");
  }
}

body {
  font-family: "Work Sans";
  overscroll-behavior: none;
  // height: 100%;

  &.blur-application-foreground {
    #application-container {
      filter: blur(6px);
    }
  }

  &.scroll-lock {
    overflow: hidden;
  }
}

h1 {
  color: #333;
  font-family: $secondary-font-family;
}

h2 {
  color: #555;
  font-family: $secondary-font-family;
}

h3,
h4,
h5,
h6 {
  color: #777;
  font-family: $secondary-font-family;
}

.match-parent {
  width: 100%;
  height: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.polling-error-indicator {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 18px;
  font-size: 13px;
  color: white;
  background: red;

  text-align: center;
  font-weight: bold;
}

input {
  background-color: $form-input-text-background-color;
  border: $form-input-text-border-width $form-input-text-border-style $form-input-text-border-color;
  border-radius: $form-input-text-border-radius;
  color: $form-input-text-color;
  font-family: $form-input-text-font-family;
  font-size: $form-input-text-font-size;
  margin: $form-input-text-margin;
  outline: none;
  padding: $form-input-text-padding;
  width: 100%;

  &:focus {
    border: $form-input-text-focus-border-width $form-input-text-focus-border-style
      $form-input-text-focus-border-color;
  }

  &::placeholder {
    color: $form-input-text-placeholder-color;
  }

  &.input-invalid:not(:focus) {
    border-color: red;
    color: red;
  }

  &.input-valid {
    border-color: greenyellow;
    color: green;
  }
}

.accent-color {
  color: $theme-accent-color;
}

