@import "../../../../assets/stylesheets/core/dependencies";
@import "../../../../assets/stylesheets/core/foundation-breakpoint";

.topic-player-ideas-view-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.topic-player-ideas-view-holder {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  .topic-player-ideas-view {
    overflow-x: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
    overflow-y: auto;
    background: #222;
    outline: 4px solid #222;
    width: 600px;

    &:focus {
      outline: none;
    }

    @mixin focus-visible {
      outline: 4px solid rgba($color: #fff, $alpha: 0.2);
    }

    &:focus-visible {
      @include focus-visible;
    }

    &.focus-visible {
      @include focus-visible;
    }

    @media(max-width: 1024px) {
      width: 100%;
    }

    @include breakpoint(small down) {
      outline: none;
      right: 0px;
      top: 0px;
      bottom: 0px;
    }
  }
}
