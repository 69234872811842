@import "../../../../assets/stylesheets/core/dependencies";

.player-badge {
  .player-avatar-container {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    padding: 6px;
    border-radius: 8px;
    background: $theme-primary-color;
    color: white;
    z-index: 10;
    padding: 8px;

    .player-avatar {
      margin-right: 8px;
    }
  }
}
