@use "sass:math";

@import "../../assets/stylesheets/core/dependencies";

$cardAspect: math.div(400, 480);
$cardWidth: 220px;
$cardHeight: math.div($cardWidth, $cardAspect);
$largeCardBreakpoint: 1700px;

.card-faces-container {
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: relative;
  top: 0;
  left: 0;
  width: $cardWidth;
  height: $cardHeight;
  transition: 200ms transform, 300ms opacity;

  &.fade-out {
    opacity: 0;
  }

  @media(min-width: 768px) {
    &:not(.discarded):hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  @media(min-width: $largeCardBreakpoint) {
    width: $cardWidth * 1.2;
    height: $cardHeight * 1.2;
  }
}

.card {
  background-color: #fff;
  box-shadow: rgb(10 33 115 / 20%) 0px 19px 38px, rgba(0, 0, 0, 0.1) 0px 15px 12px;
  border-radius: 8px;
  font-family: "Work Sans";
  white-space: normal;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  justify-content: center;

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 8px;
    right: 8px;
    top: 0;
    bottom: 0;
  }

  .card-title-container {
    height: 50%;
    width: calc(100% - 16px);
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .card-title {
      font-family: $primary-font-family;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: 600;
      font-size: 18px;
      color: green;
      text-transform: uppercase;
      text-align: center;
      white-space: normal;
      hyphens: auto;
      letter-spacing: -0.5px;
    }
  }

  .card-separator {
    width: calc(100% - 16px);
    height: 2px;
    background-color: green;
    margin-left: auto;
    margin-right: auto;
  }

  .card-description {
    width: calc(100% - 16px);
    color: #515151;
    line-height: normal;
    text-align: center;
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: $largeCardBreakpoint) {
      font-size: 16px;
    }
  }

  .card-suit-img {
    position: absolute;
    display: block;
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.card-suit-img-1 { 
      top: 10px;
      left: 10px;
    }

    &.card-suit-img-2 {
      right: 10px;
      bottom: 10px;
    }
  }
}

.card-back {
  pointer-events: none;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  width: 100%;
  height: 100%;
  display: block;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 8px;
  background-size: contain !important;
}

@media(orientation: portrait) {
  .card-collection-container {
    padding-bottom: 60px;
    transform: translateZ(10px);

    &.with-empty-actions {
      bottom: 0;
    }

    .card-collection-view {
      margin-bottom: 80px;
    }
  }
}
