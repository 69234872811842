.deck-view-board {
  display: flex;
  justify-content: center;

  &.padded {
    @media(min-width: 1440px) {
      padding-left: 0;
      left: 0;
      align-items: center;
    }
  }
}

.card-collection-container {
  position: relative;

  &:focus {
    outline: none;
  }

  @mixin focus-visible {
    background-color: rgba($color: #fff, $alpha: 0.2);
    outline: none;
  }

  &:focus-visible {
    @include focus-visible;
  }

  &.focus-visible {
    @include focus-visible;
  }

  @media(orientation: portrait) {
    width: 100%;
  }
}

.view-subject {
  position: fixed;
  transform: translateZ(100px);
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0px;
  padding-left: 40px;
  height: 50px;
  font-size: 16px;
  color: white;
  font-family: $secondary-font-family;
  font-weight: 600;
  background: $theme-primary-color;

  &.hidden {
    display: none;
  }

  .view-subject-label {
    align-items: center;
    text-align: right;
    padding-right: 20px;
    text-transform: uppercase;
  }

  .view-subject-subtitle {
    text-transform: none;
  }

  @media(orientation: portrait) {
    top: unset;
    position: fixed;
    bottom: 0;
    bottom: calc(env(safe-area-inset-bottom) + #{$actions-bar-portrait-height} + 2 * #{$actions-bar-portrait-padding});
    right: 0px;
    left: 0px;
    width: auto;
    height: auto;
    padding: 0.5em;
    border-radius: 0;

    margin-bottom: 0;
    margin-bottom: constant(safe-area-inset-bottom, 0px);
    margin-bottom: env(safe-area-inset-bottom, 0px);

    &.with-empty-actions {
      bottom: 0;
    }

    .view-subject-label {
      text-align: center;
      padding-right: 0;
    }
  }
}

@media(max-width: 1024px) {
  @media(orientation: landscape) {
    .view-subject {
      transform: scale(0.6);
      transform-origin: 100% 0;
    }
  }
}

@media(max-width: 480px) {
  @media(orientation: portrait) {
    .view-subject-label {
      font-size: 14px;
    }
  }
}