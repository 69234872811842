@import "../../../../assets/stylesheets/core/dependencies";

.self-info-container {
  margin-bottom: 40px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media(max-width: 640px) {
    border-radius: 0;
  }

  h2.you-are-header {
    margin-top: 0px;
    font-size: 20px;
  }

  .player-avatar {
    margin: auto;
    margin-bottom: 20px;
  }

  .name-input {
    text-align: center;
    position: relative;

    input {
      background: none;
      border: none;
      border-bottom: 1px solid rgba($color: black, $alpha: 0.5);
      text-align: center;
      width: 400px;
      max-width: calc(100% - 80px);
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      height: 30px;
      margin: auto;
      margin-left: 8px;
    }

    .edit-name-indicator {
      color: $theme-complementary-color;
      background: none;
      border: none;
      opacity: 0.3;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
}
