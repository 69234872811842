@import "../../../../assets/stylesheets/core/dependencies";

.crystallize-notes-tab {
  padding: 20px;

  .game-note {
    margin-top: 40px;

    p {
      padding-top: 12px;
      padding-bottom: 12px;
      white-space: pre-wrap;
      border-bottom: 1px solid #aaa;
    }

    .candidate-notes {
      padding-left: 20px;

      .candidate-note-item {
        h3 {
          text-transform: uppercase;
          font-size: 14px;
          display: flex;
          align-items: center;

          &.suit-clubs {
            color: $clubs-color;
          }
          &.suit-diamonds {
            color: $diamonds-color;
          }
          &.suit-hearts {
            color: $hearts-color;
          }
          &.suit-spades {
            color: $spades-color;
          }

          .suit-icon {
            margin-right: 4px;
          }
        }

        .player-game-notes-list-item {
          display: flex;
          gap: 8px;
          font-size: 14px;
          padding-bottom: 4px;
          margin-bottom: 4px;
          border-bottom: 1px solid #eee;
    
          .player-avatar {
            flex-shrink: 0;
          }
    
          .game-note-text {
            align-self: center;
            color: #333;
          }
          &:last-child {
            border-bottom: none;
          }
        }    
      }
    }
  }
}