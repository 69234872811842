@import "../../../assets/stylesheets/core/dependencies.scss";

.mini-card-face {
  padding: 12px;
  padding-right: 20px;
  border-radius: 8px;
  max-width: 300px;
  background: white;

  &.suit-clubs {
    color: $clubs-color;
  }
  &.suit-diamonds {
    color: $diamonds-color;
  }
  &.suit-hearts {
    color: $hearts-color;
  }
  &.suit-spades {
    color: $spades-color;
  }

  .card-title {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    display: flex;
    margin: 0;
    align-items: center;

    &.with-description {
      margin-bottom: 4px;
      padding-bottom: 4px;
      border-bottom: 1px solid #333;
    }

    &.suit-clubs {
      border-color: $clubs-color;
      color: $clubs-color;
    }
    &.suit-diamonds {
      border-color: $diamonds-color;
      color: $diamonds-color;
    }
    &.suit-hearts {
      border-color: $hearts-color;
      color: $hearts-color;
    }
    &.suit-spades {
      border-color: $spades-color;
      color: $spades-color;
    }

    .suit-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &.suit-clubs {
        background-image: url("../../../assets/images/clubs.png");
      }
      &.suit-diamonds {
        background-image: url("../../../assets/images/diamonds.png");
      }
      &.suit-hearts {
        background-image: url("../../../assets/images/hearts.png");
      }
      &.suit-spades {
        background-image: url("../../../assets/images/spades.png");
      }
    }
  }

  .card-description {
    margin: 0;
    font-size: 14px;
    text-align: left;
    line-height: 1.1em;
    padding-left: 35px;
  }
}