@import "../../../../../assets/stylesheets/core/dependencies";

.pick-candidate-one-on-one-mode-content {
  .played-cards-bar {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    position: sticky;
    top: 0;
    background: white;
    z-index: 999;
    transform: translateZ(100px);

    @media(orientation: landscape) {
      margin-bottom: 20px;
    }
  }

  .state-help-message {
    color: $theme-primary-color-lighter;
    text-align: center;
    padding: 12px;
    font-weight: 600;
  }
}