@use "sass:math";

@import "../vendor/foundation/settings";

$rem-base: 16px;

@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: math.div(strip-unit($value), strip-unit($base-value)) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

/// Wraps a media query around the content you put inside the mixin. This mixin accepts a number of values:
///  - If a string is passed, the mixin will look for it in the $breakpoints map, and use a media query there.
///  - If a pixel value is passed, it will be converted to an em value using $rem-base.
///  - If a rem value is passed, the unit will be changed to em.
///  - If an em value is passed, the value will be used as-is.
///
/// @param {mixed} $val - Breakpoint name or px/em/rem value to process.
///
/// @output If the breakpoint is "0px and larger", outputs the content. Otherwise, outputs the content wrapped in a media query.
@mixin breakpoint($val: small) {
  // Size or keyword
  $bp: nth($val, 1);
  // Value for max-width media queries
  $bpMax: 0;
  // Direction of media query (up, down, or only)
  $dir: if(length($val) > 1, nth($val, 2), up);
  // Eventual output
  $str: 'only screen';
  // Is it a named media query?
  $named: false;

  // Orientation media queries have a unique syntax
  @if $bp == 'landscape' or $bp == 'portrait' {
    $str: $str + ' and (orientation: #{$bp})';
  }

  @else {
    // Try to pull a named breakpoint out of the $breakpoints map
    @if type-of($bp) == 'string' {
      @if map-has-key($breakpoints, $bp) {
        @if $dir == 'only' {
          $next-bp: map-next($breakpoints, $bp);
          @if $next-bp == null {
            $bpMax: null;
          }
          @else {
            $bpMax: $next-bp - (1/16);
          }
        }
        $bp: map-get($breakpoints, $bp);
        $named: true;
      }
      @else {
        $bp: 0;
      }
    }

    // Pixel and unitless values are converted to rems
    @if unit($bp) == 'px' or unit($bp) == '' {
      $bp: rem-calc($bp);
    }
    // Finally, the rem value is turned into an em value
    $bp: strip-unit($bp) * 1em;

    // Skip media query creation if the input is "0 up" or "0 down"
    @if $bp > 0 or $dir == 'only' {
      // And lo, a media query was born
      @if $dir == 'only' {
        @if $named == true {
          $str: $str + ' and (min-width: #{$bp})';
          @if $bpMax != null {
            $str: $str + ' and (max-width: #{$bpMax})';
          }
        }
        @else {
          @debug 'ERROR: Only named media queries can have an "only" range.';
        }
      }
      @else if $dir == 'down' {
        $max: $bp - math.div(1, 16);
        $str: $str + ' and (max-width: #{$max})';
      }
      @else {
        $str: $str + ' and (min-width: #{$bp})';
      }
    }
  }

  // Output
  @if $bp == 0em and $dir != 'only' {
    @content;
  }
  @else {
    @media #{$str} {
      @content;
    }
  }
}
