.error-boundary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $theme-primary-color;

  .error-boundary-content {
    width: 600px;
    max-width: 100%;
    background: $theme-primary-color-extralight;
    border-radius: 8px;
    padding: 30px;

    h1 {
      margin-bottom: 48px;
      font-family: "Poppins", sans-serif;
    }
  }
}
