@use "sass:math";
@import "../../../assets/stylesheets/core/dependencies";

$cardAspect: math.div(400, 480);
$cardWidth: 220px;
$cardHeight: math.div($cardWidth, $cardAspect) + 36px; // stack of 4 cards requires more height 
$largeCardBreakpoint: 1700px;
$smallCardBreakpoint: 1200px;

.card-collection-view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  .card-slot {
    &.with-perspective {
      height: 330px;
    }
  }

  &.disabled {
    .card-faces-container {
      cursor: default;

      &:hover {
        transform: none;
      }
    }
  }

  @media (orientation: landscape) {
    &.size-small {
      transform: scale(0.9);
    }
    @media (max-width: $smallCardBreakpoint) {
      transform: scale(0.8);
    }
  }

  .card-slot {
    margin-left: 15px;
    margin-right: 15px;
    width: $cardWidth;
    height: $cardHeight;

    @media (min-width: $largeCardBreakpoint) {
      width: $cardWidth * 1.2;
      height: $cardHeight * 1.2;
    }
  }

  &.layout-vertical {
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .card-slot {
      margin-bottom: 20px;
      margin-top: 15px;
    }
  }

  &.layout-horizontal {
    padding-right: 20px;
    perspective: 800px;
    perspective-origin: top;
    row-gap: 30px;

    @media (max-height: 500px) {
      margin-top: 0px;
    }

    .card-slot {
      &.with-perspective {
        margin-left: 40px;
        margin-right: 40px;
      }
    }
  }

  .card-layout-posed-stack {
    position: relative;

    .card-slot-pose {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      &.with-perspective {
        .card-selected-icon {
          margin-top: 40px;
        }

        .card-votes-count-icon {
          top: 40px;
        }

        .card-slot-icon-actions {
          top: 40px;
        }
      }

      &:focus {
        outline: none;
      }

      @mixin card-focus-shared {
        outline: none;

        .card-faces-container {
          border-radius: 8px;
          box-shadow: 0 0 0 4px $theme-complementary-color;
        }
      }

      &:focus-visible {
        @include card-focus-shared;
      }

      &.focus-visible {
        @include card-focus-shared;
      }
    }

    .card-votes-count-icon {
      position: absolute;
      transform: translateZ(5px);
      top: 8px;
      right: 8px;
      min-width: 44px;
      height: 28px;
      border-radius: 22px;
      background: #2fd048;
      color: white;
      font-weight: 600;
      pointer-events: none;
      line-height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &.with-actions {
        right: 44px;
      }

      &.stacked {
        top: 1em;
        right: 1em;
      }

      .icon {
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }
    }
  }
}
