@use "sass:math";

.topaasia-card-decoration {
  $aspect: math.div(5, 6);
  $width: 220px;
  $height: math.div($width, $aspect);
  margin: auto;

  width: $width;
  height: $height;
  background-size: contain;
}
