@use "sass:math";

@import "../../../assets/stylesheets/core/dependencies";
@import "../../../assets/stylesheets/core/foundation-breakpoint";

html.complete-view-html {
  background: url("../../../assets/images/dark-background.png");
}

.complete-view {
  width: 700px;
  max-width: calc(100% - 40px);
  padding: 20px;
  margin: auto;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @include breakpoint(small down) {
    padding-left: 8px;
    padding-right: 8px;
    max-width: calc(100% - 20px);
  }

  .topaasia-decor-container {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(small down) {
      .topaasia-card-decoration {
        $aspect: math.div(5, 6);
        $width: 160px;
        $height: math.div($width, $aspect);
  
        width: $width;
        height: $height;
      }
    }
  }

  h1, h2, h3 {
    font-family: "Poppins", sans-serif;
    color: $theme-accent-color;
  }

  p {
    color: #666;
  }

  h1 {
    margin-bottom: 40px;

    @include breakpoint(small down) {
      font-size: 26px;
      margin-bottom: 30px;
    }
  }

  h2 {
    @include breakpoint(small down) {
      font-size: 20px;
    }
  }

  .feedback-container {
    border-radius: 12px;
    padding: 20px;
    text-align: center;

    textarea {
      width: calc(100% - 40px);
      resize: none;
    }
  }

  .game-report-container {
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;

    .email-input-container {
      width: 500px;
      max-width: 100%;
      margin: auto;
      position: relative;

      input {
        max-width: calc(100% - 40px);
        text-align: center;
        background: none;
        border: none;
        line-height: 38px;
        border-bottom: 1px solid rgb(0 0 0 / 50%);
        font-size: 18px;
        font-family: "Poppins", sans-serif;

        &:hover {
          background-color: rgba($color: white, $alpha: 0.1);
        }
      }
      
      .email-address-invalid-icon {
        position: absolute;
        top: 12px;
        right: 20px;
      }
    }
  }

  .actions {
    margin-top: 40px;
  }
}
