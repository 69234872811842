@import "../../../../assets/stylesheets/core/dependencies";
@import "../../../../assets/stylesheets/core/foundation-breakpoint";

.action-plan {
  .finding-actions {
    margin-bottom: 20px;
  }

  .no-action-items-label {
    padding: 12px;
    color: #C2CDD4;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .action-items {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .action-item {
      margin-bottom: 12px;
      border-radius: 4px;

      .light-content-container {
        border-left: 8px solid $theme-accent-color;
      }

      .action-item-field {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        @include breakpoint(large up) {
          .label-cell {
            display: flex;
            align-items: center;
            justify-content: right;

            .action-item-field-label {
              margin-bottom: 0px;
            }
          }
        }

        .action-item-field-value {
          margin-left: 8px;
          
        }

        .name-value {
          font-weight: 500;
        }

        .assignee-value {
          color: $theme-primary-color-lighter;
        }

        .due-at-value {
          color: $theme-primary-color-lighter;
        }
      }
    }
  }
}