@use "sass:math";

@import "../../assets/stylesheets/core/dependencies";
@import "../../assets/stylesheets/core/foundation-breakpoint";

.join-view-html {
  height: unset;
  overflow: unset;
  position: relative;

  background-image: url("../../assets/images/dark-background.png");
}

.join-view {
  .param-error-container {
    background: #ff574a;
    color: white;
    font-weight: 600;
    padding: 8px;
    border-radius: 6px;
    max-width: 320px;
    margin: auto;
    margin-bottom: 20px;
  }

  @media(max-height: 800px) {
    .join-card-decor {
      width: 160px;
      height: math.div(160px, math.div(5, 6));
    }
  }

  @media(max-height: 650px) {
    .join-card-decor {
      width: 140px;
      height: math.div(140px, math.div(5, 6));
    }
  }
}

.join-dialog {
  padding: 12px;
  margin: auto;
  border-radius: 0;
  min-width: 33%;
  max-width: 600px;
  text-align: center;
  margin-top: 8vh;

  @media(max-width: 700px) {
    box-shadow: none;
  }

  @media(max-height: 768px) {
    margin-top: 0px;
  }

  @media(max-height: 1024px) {
    margin-top: 20px;
  }

  h1 {
    color: $theme-accent-color;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 26px;
  }

  input {
    display: block;
    margin: auto;
  }

  .join-new-game-content {
    margin-top: 60px;
    margin-bottom: 60px;

    @media(max-height: 800px) {
      margin-top: 40px;
    }
  }

  .join-form {
    width: 450px;
    max-width: calc(100% - 24px);
    margin: auto;
    margin-bottom: 40px;

    .join-form-inputs-container {
      padding: 40px;
      background-color: $theme-primary-color-darker;
      border-radius: 8px;

      @include breakpoint(small down) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: none;
      }
    }

    @include breakpoint(medium down) {
      width: 340px;
    }
  }

  .join-input-container {
    margin-bottom: 16px;

    @media(max-width: 800px) {
      margin-bottom: 22px;
    }

    .join-input-label-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      text-align: left;

      &.error {
        color: #ff9d9d;
      }

      margin-bottom: 8px;
      padding-left: 7px;
    }

    .input-field {
      position: relative;
      display: flex;
      align-items: center;

      input {
        background: none;
        border: none;
        border-bottom: 1px solid $theme-primary-color-lighter;
        border-radius: 0px;
        color: $text-color;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        height: 36px;

        &:hover {
          background-color: rgba($color: $theme-primary-color, $alpha: 0.1);
        }

        &.input-invalid:not(:focus) {
          border-color: orangered;
          color: orangered;
        }
      
        &.input-valid {
          border-color: $theme-primary-color-lighter;
          color: $text-color;
        }
      }

      #gamecode-input {
        letter-spacing: 8px;
        font-weight: 300;

        &::placeholder {
          color: #AAA;
          letter-spacing: normal;
          font-size: 18px;
        }
      }

      .error-label {
        color: #ff9d9d;
        margin-left: 0.5cm;
        font-size: 14px;
        display: block;
        position: absolute;
        width: 200px;
        top: 68px;
        right: 0px;
        text-align: right;
      }
    }
    
    label {
      text-transform: uppercase;
      color: $text-color;
      font-size: 15px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
    }

    .label-help {
      margin: 0;
      margin-top: 2px;
      padding: 0;
      color: $theme-primary-color-lighter;
      font-size: 14px;
    }
  }

  .join-game-action {
    margin-top: 40px;
  }

  @media(max-width: 768px) {
    h1 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}