@import "../../../assets/stylesheets/core/dependencies";

$crystallize-nav-height: 50px;

.crystallize-view {
  min-height: calc(100vh - #{$crystallize-nav-height});

  h1 {
    color: $theme-accent-color;
    font-size: 22px;
    text-align: center;
  }

  h2, h3 {
    color: $theme-accent-four-color;
    font-size: 18px;
  }

  .crystallize-topaasia-card {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    h3 {
      margin: 0;
      color: white;
      transform: translateY(-40px);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 6px;
      border-radius: 8px;
      font-family: "Poppins", sans-serif;
      text-align: center;
      background-color: $theme-accent-color;
    }
  }

  .crystallize-plan-game-subject {
    color: $theme-complementary-color;
    padding: 12px;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }

  .crystallize-view-tab {
    padding-bottom: $crystallize-nav-height;

    .conclusion-row {
      margin-top: 40px;
    }

    &.focus-visible {
      background-color: rgba($color: #fff, $alpha: 0.1)
    }

    @media(min-width: 1024px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: $crystallize-nav-height;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .crystallize-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: $crystallize-nav-height;
    background: url("../../../assets/images/dark-background.png");
    background-size: 128px;
    
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .nav-item-inner {
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: $theme-primary-color-white;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 6px;
        }

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }

      &.selected {
        .nav-item-inner {
          background: white;
          color: $theme-primary-color;
        }
      }

      &:focus {
        outline: none;
      }

      @mixin focus-visible {
        outline: none;
        background-color: rgba($color: #fff, $alpha: 0.2);
      }

      &:focus-visible {
        @include focus-visible;
      }

      &.focus-visible {
        @include focus-visible;
      }
    }
  }
}
