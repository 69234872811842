.modal-message-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  perspective: 600px;
  z-index: 1000;
  transform: translateZ(1000px);
  transform-style: preserve-3d;

  .modal-shade {
    position: fixed;
    // background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    background: url("../images/dark-background.png");
    // background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.6) 0%, rgb(17 21 46) 100%);
  }
}

.modal-message-content {
  width: 400px;
  max-width: 400px;
  height: auto !important;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.3);
  padding: 30px;
  z-index: 999;
  margin: auto;
  font-family: "Work Sans";

  &:focus,
  &:focus-visible,
  &.focus-visible {
    outline: none;
  }

  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  @media (max-height: 700px) {
    max-height: 100%;
    width: 350px;
    max-width: 350px !important;
    max-width: 80%;
    border-radius: 0;

    .card-decor {
      display: none;
    }
  }

  .card-decor {
    margin: auto;
    background-size: contain !important;
  }

  h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: $theme-primary-color-darker;
    max-width: 100%;
  }

  p {
    color: $theme-primary-color;
    font-weight: 300;
    text-align: center;
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .modal-buttons-container {
    text-align: center;

    .dismiss-button {
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
