@import "../../../../../assets/stylesheets/core/dependencies";

.played-cards-bar {
  display: flex;
  padding: 12px;
  gap: 12px;

  .played-card-slot {
    width: calc(33% - 8px);
    min-width: calc(33% - 8px);
    max-width: calc(33% - 8px);
    height: 80px;
    border: 1px dashed $theme-accent-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.with-card {
      border: 1px solid white;
    }

    .card-placeholder {
      color: $theme-accent-color;
      font-weight: 300;
    }

    @media(max-width: 600px) {
      height: 60px;
    }
  }
}