@import "../../../assets/stylesheets/core/dependencies";

.join-existing-game-content {
  width: 500px;
  max-width: calc(100% - 24px);
  margin: auto;

  .game-found-title {
    margin-top: 40px;
    color: $theme-accent-color;
    text-align: left;
  }

  .game-found-description {
    color: $theme-primary-color-extralight;
    text-align: left;
  }

  .game-preview {
    background: $theme-primary-color-lighter;
    padding: 20px;
    border-radius: 8px;
    margin-top: 24px;
    display: flex;
    align-items: center;

    .game-info {
      padding-left: 20px;

      .deck-title {
        color: white;
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        justify-content: start;
        align-items: center;
        display: flex;
  
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          display: inline-block;
          background-size: contain;
          margin-right: 8px;
          background-image: url("../../../assets/images/cards-icon.png");
        }
      }
  
      .game-subject {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-top: 12px;
        text-align: left;
      }
    }
  }

  .actions {
    display: flex;
    margin-top: 30px;

    .action-column {
      flex-grow: 0.5;

      .button {
        width: 100%;
        max-width: none;
      }
    }

    @media(max-width: 450px) {
      display: block;

      .join-new-action {
        margin-top: 20px;
      }
    }
  }
}