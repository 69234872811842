.chosen-card-dialog {
  position: fixed;
  inset: 0;
  z-index: 1000;
  // background-color: rgba($color: black, $alpha: 0.5);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(17, 21, 46, 0.9) 100%);

  display: flex;
  align-items: center;
  justify-content: center;

  .chosen-card-dialog-content {
    width: calc(100% - 24px);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .actions {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 12px;
      width: 100%;

      .button.default {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 30px;
      }

      .button.subtle {
        color: white;
      }
    }
  }
}
