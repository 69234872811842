@import "../../../../assets/stylesheets/core/dependencies";

.finding {
  .finding-report {
    margin-top: 10px;
    margin-bottom: 20px;
    white-space: pre-wrap;
    color: $theme-primary-color-lighter;

    &.empty-report {
      color: $theme-primary-color-extralight;
    }
  }
}
