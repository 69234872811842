@import "../../assets/stylesheets/core/dependencies";

.action-bar {
  position: fixed;
  z-index: 999;
  transform: translateZ(20px);
  transform-style: preserve-3d;

  @media (orientation: portrait) {
    left: 0;
    bottom: constant(safe-area-inset-bottom, 0px);
    bottom: env(safe-area-inset-bottom, 0px);
    width: 100%;
    height: 100px;
  }

  @media (orientation: landscape) {
    width: auto;
    height: 100%;

    @media (min-width: 1440px) {
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
    }
  }

  .notch-padding {
    position: fixed;
    width: 0;
    height: 0;
    background: #435b68;
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    height: constant(safe-area-inset-bottom, 0px);
    height: env(safe-area-inset-bottom, 0px);
  }
}

.card-actions-container {
  padding: $actions-bar-portrait-padding;
  height: $actions-bar-portrait-height;

  @media(orientation: landscape) {
    height: $actions-bar-landscape-height;
  }

  transform: translateZ(100px);
  transform-style: preserve-3d;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  background: $theme-primary-color;
  background-image: url("../../assets/images/dark-background.png");
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: center;
  justify-content: space-evenly;

  .button-motion {
    display: inline-block;
    background: transparent;
    box-shadow: none;
    text-align: center;
    white-space: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      padding: 8px;
      background: none;
      margin: 0;
      outline: none;
      border: none;

      span {
        width: 90px;
        max-width: 100%;
        color: $theme-complementary-color;
        text-transform: uppercase;
        font-weight: bold;
      }

      @mixin card-action-button-focus {
        background: rgba($color: #fff, $alpha: 0.2);
      }

      &:focus-visible {
        @include card-action-button-focus;
      }

      &.focus-visible {
        @include card-action-button-focus;
      }

      .button-icon {
        display: block;
        margin-bottom: 12px;
        width: 100%;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      @media(orientation: portrait) {
        display: flex;
        align-items: center;
        gap: 12px;

        .button-icon {
          margin-bottom: 0px;
        }

        span {
          text-align: left;
        }
      }
    }
  }
}

@media (max-height: 1024px) {
  .card-actions-container {
    bottom: 0;
  }
}

@media (max-height: 650px) {
  .card-actions-container {
    bottom: 0;
  }
}

@media (max-width: 1200px) {
  .card-actions-container {
    bottom: 0;

    .button {
      img {
        width: 100%;
        height: 30px;
      }
    }
  }
}

@media (orientation: landscape) {
  .card-actions-container {
    height: auto;
    width: calc(100% - 24px);
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    height: 150px;
    position: fixed;
    top: unset;
    align-items: center;
    left: 0;
    bottom: 40px;
    align-self: center;
    flex-direction: row;

    .button {
      margin-left: 40px;
      margin-right: 40px;

      span {
        font-size: 14px;
      }
    }

    &.empty {
      opacity: 0;
      transform: translateY(50);
      transition: all 250ms;
    }
  }
}

@media (orientation: portrait) {
  .card-actions-container {
    padding: 0;
    flex-direction: row;

    font-size: 12px;

    margin-bottom: 0;
    margin-bottom: constant(safe-area-inset-bottom, 0px);
    margin-bottom: env(safe-area-inset-bottom, 0px);

    bottom: 0;

    &.empty {
      height: 0;
    }
  }
}
