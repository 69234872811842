@import "../../../assets/stylesheets/core/dependencies";

.waitplayers-view-html {
  height: unset;
  overflow: unset;
  position: relative;
  background: url("../../../assets/images/dark-background.png");

  body {
    height: unset;
    overflow: unset;
  }
}

.waitplayers-board {
  overflow-x: hidden;

  .game-subject {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    padding: 20px;
    max-width: 100%;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    margin: auto;
    // margin-top: 20px;
    margin-bottom: 0px;

    @media(max-width: 640px) {
      border-radius: 0;
    }

    .game-subject-label {
      color: $theme-complementary-color;
    }

    .deck-info {
      margin-top: 12px;
      text-transform: uppercase;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $theme-accent-color;

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background-size: contain;
        margin-right: 8px;
        background-image: url("../../../assets/images/cards-icon-accent.png");
      }
    }

    .perspectives-info {
      margin-top: 8px;
      font-size: 16px;
      color: $theme-accent-four-color;
      font-family: $primary-font-family;
    }

    @media(max-height: 700px) {
      font-size: 14px;
      padding: 12px;

      .deck-info {
        font-size: 14px;
      }

      .perspectives-info {
        font-size: 14px;
      }
    }
  }
}

.waitplayers-state-container {
  display: flex;
  justify-content: center;

  .waitplayers-title {
    @media(max-width: 500px) {
      font-size: 20px;
    }
  }

  .waitplayers-subtitle {
    font-weight: normal;

    @media(max-width: 500px) {
      font-size: 18px;
    }
  }

  .joined-players-title {
    @media(max-width: 500px) {
      font-size: 18px;
    }
  }

  .waitplayers-state-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-top: 80px;
    padding-bottom: $actions-bar-portrait-height;

    @media(max-height: 700px) {
      padding-top: 20px;
    }
    @media(max-width: 500px) {
      padding-top: 20px;
      padding-bottom: 0;

      .light-content-container {
        border-radius: 0;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        filter: drop-shadow(0px -4px 20px rgba(255, 255, 255, 0.5));
      }
    }

    .waitplayers-main-content {
      max-width: 700px;
      margin: auto;
      width: 100%;
    }

    h1 {
      text-align: center;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    h2 {
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 20px;
      font-size: 20px;
    }

    .crystal-shards-container {
      margin-bottom: 60px;
    }

    .joined-players-title {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .no-other-players {
      text-align: center;
      color: $theme-primary-color-lighter;
    }

    .waitplayers-player-ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 40px;
      margin-left: auto;
      margin-right: auto;

      .waitplayers-player-li {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        padding: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        background-color: white;
        box-shadow: 0px 3px 20px rgba($color: #000000, $alpha: 0.1);

        .gravatar-image {
          width: 42px;
          height: 42px;
          border-radius: 21px;
        }

        .player-email-address {
          margin-left: 20px;
        }
      }
    }
  }
}

// Mobile portrait

@media(orientation: portrait) {
  @media(max-width: 700px) {
    .waitplayers-state-container {
      .card-decor {
        width: 180px;
        height: 220px;
      }
  
      .crystal-shards-container {
        transform: scale(0.8);
        margin-bottom: 0pt !important;
        margin-top: 0px;
      }
  
      .joined-players-title {
        text-align: center;
      }
  
      h2 {
        text-align: center;
      }
    }
  }
}

@media(orientation: landscape) {
  @media(max-height: 500px) {
    .waitplayers-state-container {
      .waitplayers-state-content {
        .crystal-shards-container {
          display: none;
        }
    
        .waitplayers-player-ul {
          max-width: none;
          width: 100%;
        }

        h2 {
          text-align: center;
        }
      }
    }
  }
}
