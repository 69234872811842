@import "../../../assets/stylesheets/core/dependencies";

.card-slot {
  transform-style: preserve-3d;
  transform: rotateY(0deg);

  .card-count-indicator {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(100px);
    top: -10px;
    right: -20px;
    min-width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: $theme-primary-color-lighter;
    color: white;
    font-weight: 600;
  }
  .card-slot-icon-actions {
    position: absolute;
    top: 24px;
    right: 24px;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    .card-action-button {
      padding: 0;
      border: 0;
      background: none;
      color: $theme-accent-color;
      cursor: pointer;
    }
  }

  .processing-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 49%);
  }
  .bottom-text-container {
    display: flex;
    position: absolute;
    bottom: 2.5em;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: none;
    .bottom-text-label {
      background-color: $theme-accent-color;
      padding: 4px 8px;
      color: white;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 4px;
    }
  }
}