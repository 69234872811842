@import "../../../../../../assets/stylesheets/core/dependencies";

.player-idea {
  background-color: orange;
  display: flex;
  position: relative;
  align-items: center;
  color: white;
  font-weight: 600;
  margin: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;

  .idea-body {
    width: calc(100% - 60px);
    padding: 20px;
  }

  .idea-action {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.34, 1.56, 0.64, 1);
    box-sizing: border-box;
    border: 0px solid white;

    @media(max-width: 500px) {
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }

    @mixin focus-visible {
      outline: 4px solid rgba($color: #fff, $alpha: 0.2);
    }

    &:focus-visible {
      @include focus-visible;
    }

    &.focus-visible {
      @include focus-visible;
    }

    &.idea-action-default {
      transform: scale(0.9);
    }

    &.idea-action-active {
      transform: scale((1.2));
      border: 3px solid white;
    }
  }

  .gravatar-image {
    width: 42px;
    height: 42px;
    border-radius: 21px;
  }

  .avatar-container {
    margin-left: 8px;
  }

  .player-idea-player {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    margin-top: 8px;
  }
}
