.comment-card-dialog {
  position: fixed;
  inset: 0;
  z-index: 1000;
  // background-color: rgba($color: black, $alpha: 0.5);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(17, 21, 46, 0.9) 100%);
  backdrop-filter: blur(10px);

  display: flex;
  align-items: center;
  justify-content: center;

  .comment-card-dialog-content {
    width: calc(100% - 24px);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .comment-card-dialog-title {
      color: white;
    }

    .card-game-note-list-wrapper {
      width: 100%;
    }

    .comment-textarea-wrapper {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      .comment-textarea {
        width: calc(100% - 16px);
        border-radius: 0px;
        overflow: hidden;
        overflow-wrap: break-word;
        background: none;
        resize: none;
        border: none;
        border-bottom: 2px solid white;
        padding: 12px;
        background-color: rgba($color: black, $alpha: 0.5);
        color: white;

        &:focus {
          outline: none;
          background-color: rgba($color: black, $alpha: 0.8);
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 12px;
      width: 100%;

      .button.default {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 30px;
      }

      .button.subtle {
        color: white;
      }
    }
  }
}
