@import "../../../../assets/stylesheets/core/dependencies";

html.card-background {
  .game-info-container {
    box-shadow: 0px 2px 8px black;
  }
}

.board-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: $text-color;

  .game-info-container {
    width: calc(100% - 40px);
    color: white;
    padding: 20px;
    text-align: center;
    background: url("../../../../assets/images/dark-background.png");
    position: relative;

    &.with-player {
      @media (orientation: portrait) {
        padding-top: 60px;
      }
    }

    .player-badge-container {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  .game-subject {
    font-size: 18px;
    text-align: center;
    color: $theme-complementary-color;
    font-family: "Poppins", sans-serif;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
  }

  .board-info-header {
    color: #b6daee;
  }

  @media (max-width: 1200px) {
    position: static;
    top: 15%;
    left: 150px;
    right: 0px;
    width: unset;
  }

  @media (min-width: 1441px) {
    left: 0;
  }

  @media (orientation: portrait) {
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 20px;
    z-index: 1000;

    h1.game-subject {
      font-size: 16px;
      margin: 12px;
      font-family: "Poppins", sans-serif;
      text-align: center;

      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 8px;
        margin-top: 0px;
      }
    }

    .board-info-header {
      font-size: 14px;
      margin-top: 0;
    }

    .game-info-container {
      padding-bottom: 10px;
    }

    p {
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}
