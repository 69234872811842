@import "../../../../assets/stylesheets/core/dependencies";

.topic-player-ideas-view {
  position: relative;

  .player-ideas-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .close-button {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: orange;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: none;
  }

  .topic-title {
    padding: 20px;
    margin-top: 40px;
    color: white;
  }

  .player-idea-list-actions {
    text-align: right;
    padding-right: 12px;
    margin-bottom: 20px;

    button {
      display: inline-flex;
      align-items: center;
      padding: 4px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 20px;
      font-size: 14px;
      text-transform: none;

      .button-icon {
        margin-right: 8px;
      }
    }
  }

  .upvote-button {
    &.not-upvoted {
      .upvote-icon-content {
        opacity: 0.25;
      }
    }

    &.is-upvoted {
      filter: drop-shadow(0px 0px 6px white);
      cursor: default;
    }

    .upvote-count-label {
      color: white;
    }
  }
}
