.feedback-rating-input {
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;

  .star-button {
    font-size: 48px;
    background: none;
    border: none;
    padding: 6px;
    color: $theme-complementary-color;

    &:hover {
      color: rgba($color: $theme-complementary-color, $alpha: 0.5);
      transform: scale(1.1);
    }

    @media(max-width: 550px) {
      font-size: 28px;
    }
  }

  .dirty-indicator {
    position: absolute;
    top: 20px;
    right: -60px;

    @include breakpoint(small down) {
      top: 12px;
      right: -40px;
    }
  }
}
