.crystallize-plan-tab {
  .crystallize-meta-content {
    background-color: #202020;
    background-image: url("../../../../assets/images/dark-background.png");
    padding: 20px;
    color: white;
    border-radius: 12px;
    margin-top: 20px;
    filter: drop-shadow(2px 4px 6px black);

    .chosen-topaasia-label {
      font-weight: bold;
      text-align: center;
      font-size: 20px;
      filter: drop-shadow(0px 0px 20px white);
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .player-avatar-container {
    display: flex;
    align-items: center;

    .player-avatar {
      margin-right: 8px;
    }
  }
}