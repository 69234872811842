.feedback-text-input-container {
  position: relative;

  .feedback-text-input {
    padding: 8px;
    resize: none;
    background: rgb(234, 240, 255);
    border-radius: 6px;
  }

  .dirty-indicator {
    position: absolute;
    top: 8px;
    right: 24px;
  }
}
