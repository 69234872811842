.pick-choice-view-board {
  align-items: flex-start;
  display: block;
  
  &.padded {
    @media(min-width: 1440px) {
      padding-left: 0;
      left: 0;
      align-items: center;
      overflow-y: auto;
    }
  }

  @media(orientation: landscape) {
    overflow-y: auto;
    min-height: 100vh;
  }

  .card-collection-container {
    &.with-poll {
      // This is getting hacky but this makes room for the actions 
      // panel in desktop landscape screens.
      // @media(orientation: landscape) {
      //   @media(min-width: 1440px) {
      //     margin-bottom: 250px;
      //   }
      // }
    }

    @media(min-width: 768px) {
      .board-info {
        position: static;
        margin: auto;
        text-align: center;
      }
    }
  }

}