@import "../../assets/stylesheets/core/dependencies";

.light-content-container {
  padding: 20px;
  background-color: white;

  &.elevated {
    border-radius: 8px;
    box-shadow: 0px 5px 30px rgba($color: $theme-box-shadow-color, $alpha: 0.1);
  }

  .light-content-container-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
